import * as React from 'react'
import HealingCrystalsContainer from '../templates/healing-crystals-container'
import Reiki from '../templates/reiki'
import Testimonials from '../templates/testimonials'
import Crystals from '../templates/crystals'
import Layout from '../components/layout'
import ContactFooter from '../templates/contact-footer'
import Seo from '../components/seo'
import '../styles/index.css'

// markup
const IndexPage = () => {
  return (
    <Layout >
      <Seo title="Home" />
      <main className='pageStyles'>
        <title>Home Page</title>
        <HealingCrystalsContainer />
        <Reiki />
        <Testimonials />
        <Crystals />
        <div className='headingStyles'>
          <h1>&nbsp;</h1>
        </div>
        <ContactFooter />
      </main>
    </Layout >
  )
}

export default IndexPage
