import * as React from 'react'
import '../styles/healing-crystals.css'

const HealingCrystals = () => {
  return (
    <>
      <div className='flex col'>
        <div>
          <ul className='mainListStyle'>
            <li>* Promote peace &amp; balance *</li>
            <li>* Support the body’s natural healing processes *</li>
            <li>* Nurture emotional, mental &amp; spiritual well-being *</li>
            <li>* Reawaken &amp; nourish your divine feminine *</li>
          </ul>
          <div className='centred toBottom'>
            <a className='button' href='/contact'>Enquire about your first session</a>
          </div>
        </div>

      </div>
    </>
  )
}

export default HealingCrystals
