import * as React from 'react'
import HealingCrystals from './healing-crystals'
import HealingCrystalsImage from './healing-crystals-image'
import HealingCrystalsTitle from './healing-crystals-title'

const HealingCrystalsContainer = () => {
  return (
    <div className='mainStyles'>
      <div>
        <HealingCrystalsTitle />
        <div className='flex backgroundWhite'>
          <HealingCrystals />
          <HealingCrystalsImage />
        </div>
      </div>
    </div>
  )
}

export default HealingCrystalsContainer
