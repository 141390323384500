import * as React from 'react'

const Crystals = () => {
  return (
    <>
      <div className='headingStyles'>
        <h1>How do Crystals Work With Reiki?</h1>
      </div>
      <div className='narrowSectionStyles'>
        <div className='boxStyles'>
          <div className='centred'>
          <img
            alt="Healing crystal grid"
            src={`crystals4.jpeg`} width='50%'
          />
          </div>
          <p> Crystals have been used for their specific metaphysical healing properties for thousands of years.</p>
          <p>Crystal Reiki is a new branch of Reiki that harnesses these healing properties to amplify and focus the Reiki universal life force energy.</p>
          <p>During my sessions, I use crystals in the form of grids, and I may also lay crystals on and around you as you lie comfortably on the Reiki table. I choose the crystals based on their individual properties as well as listening to my inner guidance about what would be most helpful for you at that moment. If you're looking for support on a particular issue, you're welcome to let me know when booking, and I'll bring along a selection of crystals that help specifically with this.</p>
        </div>
      </div>
    </>
  )
}

export default Crystals
