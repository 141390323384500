import * as React from 'react'

const Reiki = () => {
  return (
    <div className='mainStyles marginBottomLarge'>
      <div className='headingStyles'>
        <h1>What is Reiki?</h1>
      </div>
      <div>
      <div className='sectionStyles'>
        <div className='boxStyles borderRadiusLeft'>
          <img
            alt="Healing crystal grid"
            src={`reiki.jpeg`} width='100%'
          />
        </div>
        <div className='boxStyles borderRadiusRight flex col'>
          <div>
            <p>
              Reiki is a beautiful, gentle form of energy healing that promotes well-being on physical, emotional, mental and spiritual levels.
            </p>
            <p>Its name means ‘universal energy’ in Japanese and, by balancing the body’s energy flow, Reiki offers many benefits including relaxation, peace, stress reduction, self-love, a clear mind, enhanced fertility, greater connection with intuition, and help with chronic pain.</p>
            <p>During a Reiki session, I will invite you to lie back fully-clothed in my zero-gravity chair, which is a bit like a long deck chair that supports your whole body. If this isn't comfortable for you, treatment can happen just as effectively with you sitting in an upright chair or on your sofa.</p>
            <p>Although I find it pleasant to play soft relaxing music, there is nothing specific that you need to do during the treatment - as Reiki is energy, it can do its work whether you are awake and alert or dozing with your eyes closed. During the session, I'll either lay my hands on your body, or just above, depending on the area. If you're not comfortable with direct touch, that's absolutely fine - just let me know and I can keep my hands hovering just above you for the entirety of the session. </p>
          </div>
        </div>
      </div>
      <div className='sectionStyles backgroundWhite'>
        <p className='columnParagraph'>People experience Reiki sessions in many different ways; some more tangibly, with feelings of heat, cold, or tingling for example, and some  more gently as a feeling of comfortable relaxation. It's possible that you may become emotional as energy blocks begin to be cleared, and this is totally fine too - you'll be in a safe and confidential space. In my experience, whatever needs to happen in a Reiki session at that time will happen, and in the way which is most beneficial for your healing.</p>
        <p className='columnParagraph'>Healing tends to carry on after the end session so I recommend staying hydrated and taking it easy for as long as possible after your session. I'll usually follow up with you a week after your session to check in - feel free to get in touch before that if you have any questions or anything to let me know.</p>
        <p className='columnParagraph'><strong>The main thing to know is that I'll centre the session around you and whatever you need at that time - there's no pressure on you to be a certain way, or do anything special during treatments.</strong> If you have any questions at all, I'd love to hear from you - there's no obligation to sign up for a session once you've contacted me. I love sharing my enthusiasm and experience of this lovely healing modality!</p>
      </div>
      <div className='centred toBottom down margin-top'>
        <a className='button' href='/contact'>Book a Reiki session</a>
      </div>
      </div>
    </div>
  )
}

export default Reiki
