import * as React from 'react'

const HealingCrystalsImage = () => {
  return (
    <div className='flex borderRadius'>
      <img
        alt="Healing crystal grid"
        src={`three-crystals-min.jpeg`} width='100%' height= '100%'
      />
    </div>
  )
}

export default HealingCrystalsImage
