import * as React from 'react'

const Testimonials = () => {
  return (
    <div className='marginBottomLarge'>
      <div className='headingStyles'>
        <h1>What people say</h1>
      </div>
      <p className='offers'></p>
      <div className='fees marginBottom'>
        <div className='testimonials'>
          <p>“Kirstie has such a gentle, calming presence and energy. I felt totally at ease throughout the reiki session, to which I was a total newbie. I didn’t know what to expect but left feeling as though Kirstie had reset my energy and felt an immediate sense of calm and balance. Thank you Kirstie!”</p>
          <p>- Sophie</p>
        </div>
        <div className='testimonials'>
          <p>“Kirstie is both a powerful and gentle healer who has cleared a lot of the energy stuck in my body. I suffer from PTSD and from the very first session with Kirstie I felt an important shift take place in my body and felt real peace and grounding in my emotional healing. Thank you so much, Kirstie.”</p>
          <p>- Alice</p>
        </div>
        <div className='testimonials'>
          <p>“Kirstie is an amazing reiki therapist, I was feeling really stressed and it was just what I needed.  She is really intuitive and her space was so relaxing with crystals, music and her newly renovated van.  I would highly recommend it to anyone needing some healing.  Thank you so much!“</p>
          <p>- Z</p>
       </div>
      </div>
      <div className='centred toBottom down'>
          <a className='button' href='/contact'>Start your healing journey</a>
        </div>
    </div>
  )
}

export default Testimonials
