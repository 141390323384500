import * as React from 'react'

const HealingCrystalsTitle = () => {
  return (
    <h1 className='mainHeadingStyles backgroundWhite padding'>
      Healing Crystals Reiki
      <br />
    <span className='headingAccentStyles'>Crystal Reiki &amp; <span className='avoidWrap'>sacred feminine healing</span></span>
    </h1>
  )
}

export default HealingCrystalsTitle
